// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import Home from "../view/Home.vue";
import Recover from"../view/fitness/Recover.vue"
import Diet from "../view/fitness/Diet.vue"
import Beginner from "../view/fitness/Beginner.vue"
import Anaerobic from "../view/fitness/Anaerobic.vue"
import Aerobics from "../view/fitness/Aerobics.vue"
import Article from "../view/article.vue"
import Accidence from "../pages/beginner/accidence.vue"
import Instrument from "../pages/beginner/instrument.vue"
import Misunderstanding from "../pages/beginner/misunderstanding.vue"
import Relieve from "../pages/beginner/relieve.vue"
import Cycling from "../pages/aerobics/cycling.vue"
import Run from "../pages/aerobics/run.vue"
import Summarize from "../pages/aerobics/summarize.vue"
import Swimming from "../pages/aerobics/swimming.vue"
import Lowerlimbs from "../pages/anaerobic/lowerlimbs.vue"
import Particulars from "../pages/anaerobic/particulars.vue"
import Upperlimb from "../pages/anaerobic/upperlimb.vue"
import Waist from "../pages/anaerobic/waist.vue"
import Breakfast from "../pages/diet/breakfast.vue"
import Dietoverview from "../pages/diet/dietoverview.vue"
import Lunch from "../pages/diet/lunch.vue"
import Supper from "../pages/diet/supper.vue"
import Relax from "../pages/recover/relax.vue"
import Method from "../pages/recover/method.vue" 
import Contact from "../pages/contact.vue"
import Privacy from "../pages/privacy.vue"
import UserTerms from "../pages/userTerms.vue"
 const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
  },
  {
    path: "/diet",
    name: "Diet",
    component: Diet,
  },
  {
    path: "/beginner",
    name: "Beginner",
    component:Beginner ,
  },
  {
    path: "/anaerobic",
    name: "Anaerobic",
    component: Anaerobic,
  },
  {
    path: "/aerobics",
    name: "Aerobics",
    component: Aerobics,
  },
  //文章路由

  {
    path: "/article",
    component: Article,
    children: [
      // 入门部分
      {
        path: "accidence",
        name: "Accidence",
        component: Accidence,
      },
      {
        path: "instrument",
        name: "instrument",
        component: Instrument,
      },
      {
        path: "misunderstanding",
        name: "misunderstanding",
        component: Misunderstanding,
      },
      {
        path: "relieve",
        name: "relieve",
        component: Relieve,
      },

      //有氧部分
      {
        path: "cycling",
        name: "cycling",
        component: Cycling,
      },
      {
        path: "run",
        name: "run",
        component: Run,
      },
      {
        path: "summarize",
        name: "summarize",
        component: Summarize,
      },
      {
        path: "swimming",
        name: "swimming",
        component: Swimming,
      },
      //无氧部分
      {
        path: "lowerlimbs",
        name: "lowerlimbs",
        component: Lowerlimbs,
      },
      {
        path: "particulars",
        name: "particulars",
        component: Particulars,
      },
      {
        path: "upperlimb",
        name: "upperlimb",
        component: Upperlimb,
      },
      {
        path: "waist",
        name: "waist",
        component: Waist,
      },
      //健康饮食
      {
        path: "breakfast",
        name: "breakfast",
        component: Breakfast,
      },
      {
        path: "dietoverview",
        name: "dietoverview",
        component: Dietoverview,
      },
      {
        path: "lunch",
        name: "lunch",
        component: Lunch,
      },
      {
        path: "supper",
        name: "supper",
        component: Supper,
      },
      //放松与恢复
      {
        path: "relax",
        name: "relax",
        component: Relax,
      },
      {
        path: "method",
        name: "Method",
        component: Method,
      },
      //政策与协议
      {
        path: "contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "privacy",
        name: "privacy",
        component: Privacy,
      },
      {
        path: "userTerms",
        name: "userTerms",
        component: UserTerms,
      },

    ]
  }


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
