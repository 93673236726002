<template>
    <div>
        <h1>How to Relieve Fatigue After Exercise</h1>
        <p>
            1. Perform post-workout stretching to reduce muscle tension and improve
            blood circulation. This helps speed up recovery. You can also try yoga or
            gentle stretching exercises.
        </p>
        <p>
            2. Massage helps relax muscles, increase blood circulation, and flush out
            lactic acid. Foam rolling or professional massage can be beneficial.
        </p>
        <p>
            3. Apply ice packs to reduce muscle inflammation and use heat therapy to
            improve blood flow and relieve soreness. You can also choose to take a hot
            bath
        </p>
        <p>
            4. Eating protein (like such as chicken, fish, beef) aids muscle repair,
            If you're vegetarian，you can use beans instead while carbohydrates (like
            wholemeal bread、brown rice or sweet potatoes) replenish energy. Staying
            hydrated helps flush out toxins and reduces muscle soreness. Consider
            saline water or electrolyte drinks to maintain balance in the body.
            （Ingredients of saltwater: 1 gram of salt, 15 grams of sugar, 400
            milliliters of water）
        </p>
        <p>
            5. Aim for 8 hours of quality sleep each night.，adequate sleep is
            essential for recovery. Muscles repair and grow during sleep,
        </p>
        <p>
            6. Engage in light activities like jogging、walking or swim the day after
            your workout to promote blood circulation and aid recovery. Choose some
            nutritional supplements to help the body recover if conditions permit,
        </p>
        <h3>
            Relaxation is also an important part of fitness. These methods can
            typically help relieve post-exercise fatigue effectively. It is also
            important to adjust your recovery approach based on the intensity and
            frequency of your workouts.
        </h3>
    </div>
</template>
<style lang="scss" scoped>
h1 {
    margin: 10px 0;
}

p {
    padding: 0 14px;
    margin: 18px 0;
}
</style>
