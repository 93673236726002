<template>
  <div>
    <h1>User Terms</h1>
    <p>
      By accessing and using our website, you agree to comply with the following
      terms and conditions:
    </p>
    <h3>1. Account Responsibility</h3>
    <p>
      You are responsible for maintaining the confidentiality of your account
      and password. You agree to notify us immediately of any unauthorized use
      of your account.
    </p>
    <h3>2. Proper Website Usage</h3>
    <p>
      You must not engage in any activity that may disrupt or interfere with the
      proper functioning of our website, including but not limited to hacking,
      spamming, or transmitting malware.
    </p>
    <h3>3. Legal and Authorized Use</h3>
    <p>
      You must not use our website for any illegal or unauthorized purpose. You
      agree to comply with all applicable laws and regulations.
    </p>
    <h3>4. Content Upload</h3>
    <p>
      You must not upload or transmit any content that is unlawful, harmful,
      defamatory, or infringes upon the rights of others. You are solely
      responsible for the content you post on our website.
    </p>
    <h3>5. Suspension or Termination</h3>
    <p>
      We reserve the right to suspend or terminate your access to our website at
      any time, without prior notice, if you violate these terms and conditions.
    </p>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
