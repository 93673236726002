<template>
  <div class="policy hidden-sm-and-down">
    <ul>
      <li><router-link to="/article/privacy">Privacy Policy</router-link></li>
      <li><router-link to="/article/contact">Contact US</router-link></li>
      <li style="border-right: 0">
        <router-link style="border-right: 0" to="/article/userTerms"
          >User Terms</router-link
        >
      </li>
    </ul>
    <p>Copyright © 2024 Powered by Coldplay</p>
  </div>
</template>

<style lang="scss" scoped>

@media screen and (min-width: 768px) and (max-width: 992px) {
  .policy {
    position: fixed;
    bottom: 0;
    height: 50px;
    ul {
      margin-top: 0 !important;
    }
  }
}


@media screen and (min-width: 993px) and (max-width: 1200px) {
  .policy {
    position: fixed;
    bottom: 0;
    height: 80px;
  }
}


@media screen and (min-width: 1200px) {
  .policy {
    position: fixed;
    bottom: 0;
    height: 80px;
  }
}
.policy {
  width: 100vw;
  background-color: rgba(45, 50, 54);
  ul {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    li {
      text-align: center;
      margin: 0 10px;
      a {
        border-right: 1px solid #fff;
        padding-right: 14px;
      }
    }
  }
  p {
    color: #fff;
    text-align: center;
  }
}
</style>
