<template>
  <!-- 头部导航栏 -->
  <div class="container" style="background-color: rgba(38, 30, 21)">
    <div class="box" style="display: flex; justify-content: space-between">
      <router-link to="/" style="cursor: pointer">
        <img
          style="width: 5.25rem"
          src="../assets/0128dc59798e37a8012193a3499480.png"
          alt=""
        />
      </router-link>
      <section class="hidden-sm-and-down">
        <input
          placeholder="Type your search here ..."
          type="text"
          v-model="inputValue"
          @keydown.enter="onSearch()"
        />
        <span @click="onSearch()"
          ><img src="../assets/搜索 (1).png" alt=""
        /></span>
      </section>

      <el-menu
        ref="menu"
        style="position: sticky; top: 0"
        mode="horizontal"
        router
        :ellipsis="false"
        menu-trigger="click"
        background-color="rgb(38, 30, 21)"
        text-color="#fff"
        active-text-color="#eebe77"
      >
        <div class="menu">
          <div style="flex-grow: 1" />
          <div
            class="hidden-sm-and-down"
            style="display: flex; align-items: center"
          >
            <!-- <el-menu-item index="/beginner">Beginner</el-menu-item>
            <el-menu-item index="/aerobics">Aerobic</el-menu-item>
            <el-menu-item index="/anaerobic">Anaerobic</el-menu-item>
            <el-menu-item index="/diet">Diet</el-menu-item>
            <el-menu-item index="/recover">Recover</el-menu-item> -->
            <el-menu-item
              v-for="item in menuItems"
              :key="item.index"
              :index="item.index"
              @click="setActive(item.index)"
            >
              {{ item.text }}
            </el-menu-item>
          </div>
          <div class="hidden-md-only hidden-md-and-up">
            <el-button
              type="info"
              style="margin-top: 6px"
              @click="drawer = true"
              size="large"
            >
              <span style="color: #eebe77; font-size: 18px">MENU</span>
            </el-button>

            <el-drawer
              style="background: rgba(38, 30, 21, 0.75)"
              class="stycalss"
              direction="ltr"
              v-model="drawer"
              title="I am the title"
              :with-header="false"
              size="55%"
            >
              <ul>
                <li>
                  <el-menu-item style="color: #eebe77" index="/beginner"
                    >Beginner</el-menu-item
                  >
                </li>
                <li>
                  <el-menu-item style="color: #eebe77" index="/aerobics"
                    >Aerobic</el-menu-item
                  >
                </li>
                <li>
                  <el-menu-item style="color: #eebe77" index="/anaerobic"
                    >Anaerobic</el-menu-item
                  >
                </li>
                <li>
                  <el-menu-item style="color: #eebe77" index="/diet"
                    >Diet</el-menu-item
                  >
                </li>
                <li>
                  <el-menu-item style="color: #eebe77" index="/recover"
                    >Recover</el-menu-item
                  >
                </li>
              </ul>
              <section class="" style="width: 98%; margin-top: 20px">
                <input
                  placeholder="Type your search here ..."
                  type="text"
                  v-model="inputValue"
                  @keydown.enter="onSearch()"
                />
                <span @click="onSearch()"
                  ><img src="../assets/搜索 (1).png" alt=""
                /></span>
              </section>
            </el-drawer>
          </div>
        </div>
      </el-menu>
    </div>
  </div>
  <!-- 头部导航栏结束 -->
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
const activeIndex = ref(""); // 当前激活的菜单项索引
const menuItems = ref([
  { index: "/beginner", text: "Beginner" },
  { index: "/aerobics", text: "Aerobic" },
  { index: "/anaerobic", text: "Anaerobic" },
  { index: "/diet", text: "Diet" },
  { index: "/recover", text: "Recover" },
]);
// 设置当前激活的菜单项
const setActive = (index) => {
  activeIndex.value = index;
};
// 判断某个菜单项是否激活
const isActive = (index) => {
  return activeIndex.value === index;
};
const drawer = ref(false);
const router = useRouter();
const inputValue = ref("");
watch(inputValue, (newValue, oldValue) => {});
const arr = ref([
  { title: "beginner fitness guide", path: "beginner" },
  { title: "beginner", path: "beginner" },
  { title: "common misconception", path: "beginner" },
  { title: "fitness equipment", path: "beginner" },
  { title: "relieve fatigue", path: "beginner" },
  { title: "aerobics", path: "aerobics" },
  { title: "aerobic rercise", path: "aerobics" },
  { title: "run and jump", path: "aerobics" },
  { title: "lower limbs", path: "aerobics" },
  { title: "cycling", path: "aerobics" },
  { title: "anaerobic", path: "anaerobic" },
  { title: "anaerobic exercise", path: "anaerobic" },
  { title: "upper Body workout", path: "anaerobic" },
  { title: "how to lower body exercises", path: "anaerobic" },
  { title: "how to core strength exercises", path: "anaerobic" },
  { title: "diet", path: "diet" },
  { title: "a healthy diet", path: "diet" },
  { title: "breakfast", path: "diet" },
  { title: "lunch", path: "diet" },
  { title: "supper", path: "diet" },
  { title: "recover", path: "recover" },
  { title: "relaxation overview", path: "recover" },
  { title: "method of relaxing", path: "recover" },
]);
const onSearch = () => {
  // arr.value.map((item, index) => {
  //   if (item.title.indexOf(inputValue.value) !== -1) {
  //     router.push("/" + item.path);
  //     return;
  //   } else {
  //     console.log(index);
  //     if (index == arr.value.length - 1) {
  //       ElNotification({
  //         title: "Warning",
  //         message: "This is a warning message",
  //         type: "warning",
  //         position: "top-left",
  //       });
  //     }
  //   }
  // });
  for (let i = 0; i < arr.value.length - 1; i++) {
    if (inputValue.value.length == 1) {
      ElMessage({
        message: "This information cannot be found",
        type: "warning",
        plain: true,
        duration: 1200,
      });
      inputValue.value = "";
      return;
    }
    if (arr.value[i].title.indexOf(inputValue.value) !== -1) {
      router.push("/" + arr.value[i].path);
      return;
    } else {
      if (i == arr.value.length - 2) {
        ElMessage({
          message: "This information cannot be found",
          type: "warning",
          plain: true,
          duration: 1200,
        });
        // ElNotification({
        //   title: "warning",
        //   message: "This information cannot be found",
        //   type: "warning",
        //   position: "top-left",
        //   duration: 1600,
        // });
        inputValue.value = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.active-menu-item {
  color: #eebe77 !important;
}

@media screen and (max-width: 768px) {
  .box {
    width: 86% !important;
  }
}


@media screen and (min-width: 768px) and (max-width: 992px) {
  .box {
    width: 88% !important;
  }
}


@media screen and (min-width: 993px) and (max-width: 1200px) {
  .box {
    width: 80% !important;
  }
}


@media screen and (min-width: 1200px) {
}
.container {
  width: 100vw;
  height: 5.25rem;
  position: fixed;
  top: 0;
  z-index: 999;

  .box {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;

    section {
      width: 20%;
      position: relative;

      input {
        padding-left: 20px;
        width: 100%;
        height: 30px;
        border: 0;
        /* 边框宽度为2px，颜色为黑色 */
        border-radius: 5px;
        /* 边框圆角 */
        outline: none;
        cursor: pointer;
        background-color: none;
      }

      span {
        cursor: pointer;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        top: 4px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .el-menu-item {
      font-size: 16px;
    }

    .el-menu {
      border: 0;
    }
  }
}
</style>
