<template>
  <div>
    <h1>Contact US</h1>
    <p>
      If you have any questions, concerns, or feedback regarding our website or
      any of our policies, please feel free to contact us. You can reach us by
      email at tt2978788@gmail.com. We will do our best to respond to your
      inquiries in a timely manner.
    </p>
    <p>Thank you for visiting our website and for your continued support!</p>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
p {
  padding: 10px;
  margin: 22px;
}
</style>
