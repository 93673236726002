<template>
  <div>
    <h1>How to lower body Exercises</h1>
    <p>
      Warm up exercises are also an important part of exercise，Always warm up
      for 5-10 minutes before starting your workout with light exercises like
      jogging or rope skipping
    </p>
    <ul>
      <li>
        1. Squats：Stand with your feet shoulder-width apart. Slowly lower your
        body as if sitting back into a chair, keeping your back straight and
        thighs parallel to the ground. Return to the starting position.
      </li>
      <li>
        2. Dumbbell Squats: Hold a dumbbell in each hand, standing with your
        feet shoulder-width apart. Lower into a squat, keeping your back
        straight, then return to the starting position.
      </li>
      <li>
        3. Leg Press：Sit on the leg press machine with your back against the
        pad. Place your feet on the platform shoulder-width apart. Push the
        platform away by extending your legs, then slowly return to the starting
        position.
      </li>
      <li>
        4. Leg Curl：Sit on the leg curl machine and place your lower legs under
        the padded bar. Curl your legs toward your body by bending your knees,
        then slowly lower back to the starting position.
      </li>
      <li>
        5. Dumbbell Lunges：Holding a dumbbell in each hand, stand upright and
        step forward into a lunge. Ensure your torso remains upright and your
        front knee doesn’t go past your toes.
      </li>
    </ul>
    <p>
      Aim for 4 sets of 10-15 repetitions for each exercise. If your lower body
      strength is weak，you can aim for 3 sets of 8-12 repetitions for each
      exercise. Remember to warm up before your workout and stretch afterward to
      aid recovery!
    </p>
    <h3>Benefits of Strengthening Lower Body Muscles</h3>
    <ul>
      <li>
        1. Stronger lower body muscles contribute to better stability and
        balance, reducing the risk of falls.，and alleviate pressure on the
        knees and lower back, reducing related pain.
      </li>
      <li>
        2. Many lower body exercises also improve cardiovascular endurance,
        benefiting heart health. (like squats、lunges)
      </li>
      <li>
        3. Lower body workouts can increase muscle endurance, allowing you to
        perform physical activities for longer periods.
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
}
ul li {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px;
}
h3 {
}
</style>
