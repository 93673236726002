<template>
  <div>
    <h1>Jogging</h1>
    <p>
      Jogging is a form of aerobic exercise that involves running at a slow,
      steady pace. It is accessible to people of all fitness levels and can be
      school、park or on a treadmill. Typically, jogging speeds range from about
      6 to 10 kilometers per hour, making it a great way for beginners to
      improve their cardiovascular fitness.
    </p>
    <h3>The benefits of jogging</h3>
    <ul>
      <li>
        1. Regular jogging strengthens the heart, improves circulation, and
        enhances lung capacity. Over time, jogging builds stamina and
        strengthens leg muscles, improving overall physical performance.
      </li>
      <li>
        2. As an effective calorie-burning exercise, jogging helps in
        maintaining a healthy weight and reducing body fat.
      </li>
      <li>
        3. Jogging is a weight-bearing exercise that helps increase bone
        density, reducing the risk of osteoporosis.
      </li>
      <li>
        4. Regular aerobic activity like jogging can lead to better sleep
        patterns and improved sleep quality.
      </li>
    </ul>

    <h3>How to start jogging</h3>
    <ul>
      <li>
        1. Invest in a good pair of running shoes that provide support and
        comfort and wear breathable athletic clothing to stay comfortable while
        running.
      </li>
      <li>
        2. Use a run/walk method, such as "jog for 1 minute, walk for 1
        minutes," and gradually increase your jogging time. Establish short-term
        and long-term goals, such as jogging four times a week for 15-20 minutes
        each session.
      </li>
      <li>
        3. Keep your body relaxed, and let your arms swing naturally without
        stiffness. If you feel fatigued or uncomfortable, slow down or take a
        break as needed. Once you feel comfortable, gradually increase your
        jogging time and intensity.
      </li>
      <li>
        4. Find a safe and pleasant place to jog, such as a school or par, to
        enjoy nature. Start on flat roads to minimize the risk of injury from
        uneven terrain.
      </li>
      <li>
        5. Spend 10 minutes warming up with dynamic stretches or walking to
        prepare your body for exercise. After jogging, perform static stretches
        to help relax your muscles and reduce the risk of injury.
      </li>
    </ul>
    <h3>
      If you have any health concerns or haven’t exercised in a while, consider
      consulting a doctor before jogging. in summary, jogging is a simple yet
      effective way to enhance physical and mental health. Whether you're a
      beginner or an experienced runner, incorporating jogging into your routine
      can lead to numerous health benefits.
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 19px 0;
}
h3 {
  margin: 10px;
}
p {
  margin-left: 18px;
}
ul li {
  margin: 22px;
}
</style>
