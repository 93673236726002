<template>
  <div>
    <h1>Cycling</h1>
    <p>
      Cycling is an aerobic activity and mode of transportation that involves
      riding a bicycle. It can be enjoyed as a recreational activity, used for
      commuting, traveling, or exercising. There are various forms of cycling,
      including urban cycling, mountain biking, road cycling, and indoor
      cycling, making it suitable for people of all ages and fitness levels.
    </p>
    <h3>The Benefits of Cycling</h3>
    <ul>
      <li>
        1. Cycling is an great aerobic exercise that improves heart and lung
        health, boosting cardiovascular system function.
      </li>
      <li>
        2. Long term cycling can exercise the muscles of the lower body,
        including the quadriceps, hamstring, and calves, which can help enhance
        strength and endurance. And it can improve the body's balance and
        coordination.
      </li>
      <li>
        3. Cycling can be enjoyed with friends or family, providing social
        opportunities and strengthening relationships. enhancing overall mental
        well-being.
      </li>
    </ul>

    <h3>How to Start Cycling</h3>
    <ul>
      <li>
        1. Choose a bicycle that suits your needs, such as a road bike, mountain
        bike, commuter bike, or folding bike. Ensure that the size of the
        bicycle is suitable for your height, ensuring comfortable and safe
        riding. Adjust the seat height and handle position to achieve the best
        riding posture.
      </li>
      <li>
        2. Buy a helmet to protect your head and wear comfortable cycling
        clothing that is breathable, helping you adapt better to the ride. If
        possible, purchase gloves and knee pads to improve safety
      </li>
      <li>
        3. Determine your cycling goals, such as riding a certain distance or
        duration each day. Begin with short rides and gradually increase the
        time and distance to build endurance.
      </li>
      <li>
        4. Adhere to traffic signals and regulations to ensure safety while
        riding on the road. Choose a suitable route for cycling and avoid
        dangerous and heavily trafficked areas
      </li>
    </ul>
    <h3>
      Cycling is not only a health-promoting activity but also helps improve
      cardiovascular fitness, build muscle strength, and enhance mental
      well-being. Additionally, cycling is an eco-friendly mode of
      transportation that effectively reduces carbon emissions and protects the
      environment. Whether riding solo or with friends and family, cycling
      offers both enjoyment and a chance to relax physically and mentally. By
      following these steps, you can successfully start cycling and enjoy this
      healthy and fun activity.
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 10px 0;
}
p {
  margin-left: 14px;
  margin-bottom: 16px;
}
li {
  margin: 26px;
}
h3 {
  margin-left: 12px;
}
</style>
