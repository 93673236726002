<template>
    <div>
        <h1>Free Weights</h1>
        <p>
            Free Weights refer to strength training equipment that is not fixed to a
            machine, allowing the user to freely control the range of motion. Since
            free weights require the exerciser to maintain balance and control
            throughout the movement, they engage more stabilizing muscles, enhancing
            overall body coordination and strength.
        </p>
        <p>
            Common Types of Free Weights：Barbell、Dumbbells、Sandbags and Kettlebell
        </p>
        <h3>The Benefits of Free Weights:</h3>
        <h4>
            1. Available in various weights, they can accommodate all fitness levels,
            from beginners to advanced athletes.
        </h4>
        <h4>
            2. High degree of freedom in motion，diverse training targeting different
            muscle groups.
        </h4>
        <h4>
            3. Improving coordination and balance, Improved physical strength and
            flexibility
        </h4>
        <span>Free Weights is more suitable for advanced training, explosive power
            training, and strength training
        </span>
        <h1> Weights Machine</h1>
        <p> Weights Machine refer to strength training equipment where
            the movement path is fixed and predetermined by the machine. These machines
            are designed to isolate specific muscle groups and reduce external factors
            during the exercise.
        </p>
        <p> Common Types of Weights Machine：Pec Deck
            Machine、Seated Row Machine、Leg Press Machine and Chest Press Machine.
        </p>
        <h3> TheBenefits of Machine Weights:</h3>
        <h4> 1. More secure, compared to free weights machines require less balance and control, making them suitable
            for
            beginner、elderly and those in rehabilitation.
        </h4>
        <h4>2. Easy to use, the fixed range of motion makes it easier for beginners to learn proper form, reducing
            the risk of injury.</h4>
        <h4> 3. By restricting the movement range, machine weights
            allow for more focused training on specific muscle groups, ideal for precise
            muscle development.
        </h4>
        <h4> Machine weights are ideal for individuals who want to
            focus on specific muscle groups，It is also suitable for beginners, the
            elderly, and rehabilitation populations.</h4>
    </div>
</template>

<style lang="scss" scoped>
h1 {
    margin-top: 5px;
    margin-bottom: 10px;
}

h3 {
    margin: 5px;
}

h4 {
    margin: 10px 0;
}

p {
    margin: 4px;
}
</style>
