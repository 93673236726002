<template>
  <!-- 头部 -->

  <Head></Head>

  <!-- 卡片部分 -->
  <el-row class="merry" style="padding: 10px" justify="space-around">
    <!-- 左侧轮播部分 -->
    <el-col
      class="leftswipper"
      :xs="24"
      :sm="24"
      :md="12"
      :lg="11"
      :xl="11"
      style="box-sizing: border-box; padding: 0 10px"
    >
      <el-carousel
        height="200px"
        direction="vertical"
        :autoplay="true"
        :motion-blur="true"
        style="margin-top: 10px; border-radius: 2%; width: 96%"
      >
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="../assets/e154002750821088-e38be3ca4675d66a-88307e99ca25f5f64167524d975bd541.jpg"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="../assets/800.jpg"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="../assets/abe1eea3ca79fc28-c577ebdcb0f3dbcc-341c2597decdd3021298ca6d0768c533.jpg"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="../assets/e154002750821088-94f92ae25d049ea1-348c510803dea0f7ff50ac25919f4614.jpg"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>

      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :xs="0" :sm="6" :md="12" :lg="12" :xl="12">
          <span>
            <img
              src="../assets/a6764e962df8bc82.jpg"
              style="width: 90%; height: 86%; border-radius: 4%"
            />
          </span>
        </el-col>
        <el-col :xs="0" :sm="6" :md="12" :lg="12" :xl="12">
          <img
            src="../assets/843c3c38b8aa1eed.jpg"
            style="width: 90%; height: 86%; border-radius: 4%"
          />
        </el-col>
        <el-col :xs="0" :sm="6" :md="0" :lg="0" :xl="0">
          <span>
            <img
              src="../assets/122914e0daafe9b2.jpg"
              style="width: 90%; height: 86%; border-radius: 4%"
          /></span>
        </el-col>
        <el-col :xs="0" :sm="6" :md="0" :lg="0" :xl="0">
          <span
            ><img
              src="../assets/3fe50b98845a2c09.jpg"
              style="width: 90%; height: 86%; border-radius: 4%"
          /></span>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xs="0" :sm="0" :md="12" :lg="12" :xl="12">
          <img
            src="../assets/122914e0daafe9b2.jpg"
            style="width: 90%; height: 86%; border-radius: 4%"
          />
        </el-col>
        <el-col :xs="0" :sm="0" :md="12" :lg="12" :xl="12">
          <img
            src="../assets/3fe50b98845a2c09.jpg"
            style="width: 90%; height: 86%; border-radius: 4%"
          />
        </el-col>
      </el-row>
    </el-col>
    <!-- 右侧选项卡 -->
    <el-col
      style="padding-bottom: 20px"
      :xs="24"
      :sm="24"
      :md="12"
      :lg="12"
      :xl="12"
    >
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <!-- 健身入门 -->
        <el-tab-pane label="Beginner" name="first">
          <div class="flex flex-wrap gap-4">
            <!-- 入门 -->
            <el-card style="width: 100%" shadow="hover">
              <router-link to="/beginner">
                <section>
                  <img
                    src="../assets//c2c4fe6ea55263ee.jpg"
                    alt=""
                    style="width: 6rem; object-fit: cover"
                  />
                  <article>
                    <h2>Beginner Fitness Guide</h2>
                    <p>
                      How should a beginner get started with fitness? Here are
                      some beginner fitness tips to help you start from scratch
                      and work out effectively and healthily.
                    </p>
                  </article>
                </section>
              </router-link>
            </el-card>
            <!-- 常见误区 -->
            <el-card style="width: 100%" shadow="hover">
              <router-link to="/beginner">
                <section>
                  <img
                    src="../assets/9a89a72048969f28.jpg"
                    alt=""
                    style="width: 6rem; object-fit: cover"
                  />
                  <article>
                    <h2>Common misconception</h2>
                    <p>
                      Common Misconceptions About Starting a Fitness Journey
                    </p>
                  </article>
                </section>
              </router-link>
            </el-card>
            <!-- 自由器械 -->
            <el-card style="width: 100%" shadow="hover">
              <router-link to="/beginner">
                <section>
                  <img
                    src="../assets/c49a73c441da4fa0.jpg"
                    alt=""
                    style="width: 6rem; object-fit: cover"
                  />
                  <article>
                    <h2>Fitness equipment</h2>
                    <p>
                      Free Weights refer to strength training equipment that is
                      not fixed to a machine, allowing the user to freely
                      control th
                    </p>
                  </article>
                </section>
              </router-link>
            </el-card>
            <!-- 缓解疲劳 -->
            <el-card style="width: 100%" shadow="hover">
              <router-link to="/beginner">
                <section>
                  <img
                    src="../assets/e4b063242f51d1fe.jpg"
                    alt=""
                    style="width: 6rem; object-fit: cover"
                  />
                  <article>
                    <h2>Relieve fatigue</h2>
                    <p>
                      Perform post-workout stretching to reduce muscle tension
                      and improve blood circulation. This helps speed up
                      recovery.
                    </p>
                  </article>
                </section>
              </router-link>
            </el-card>
          </div>
        </el-tab-pane>
        <!-- 有氧健身 -->
        <el-tab-pane label="Aerobic" name="second">
          <!-- 有氧概述 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="aerobics">
              <section>
                <img
                  src="../assets/4c17881bfc46951f.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Aerobic Exercise</h2>
                  <p>
                    Aerobic exercise is a type of physical activity primarily
                    aimed at enhancing cardiovascular function. It typically
                    involves repetitive movements
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 上肢有氧 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="aerobics">
              <section>
                <img
                  src="../assets/8edeea1c4e5f9cae.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Run and jump</h2>
                  <p>
                    Jogging is a form of aerobic exercise that involves running
                    at a slow, steady pace. It is accessible to people of all
                    fitness levels and can be school、
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 下肢有氧 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="aerobics">
              <section>
                <img
                  src="../assets/7e288441d79aa342.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Lower limbs</h2>
                  <p>
                    Swimming is a full-body aerobic exercise typically performed
                    in water. It can be both a recreational activity
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>

          <!-- 腰腹运动 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="aerobics">
              <section>
                <img
                  src="../assets/c763bc0e9b3188ea.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Cycling</h2>
                  <p>
                    Cycling is an aerobic activity and mode of transportation
                    that involves riding a bicycle. It can be enjoyed as a
                    recreational activity, used for commuting,
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
        </el-tab-pane>
        <!-- 无氧运动 -->
        <el-tab-pane label="Anaerobic" name="third">
          <!-- 无氧运动概述 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="anaerobic">
              <section>
                <!-- fa -->
                <img
                  src="../assets/fab556c562b04097.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Anaerobic Exercise</h2>
                  <p>
                    Anaerobic exercise is a type of physical activity primarily
                    focused on increasing muscle strength and
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 上肢无氧 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="anaerobic">
              <section>
                <!-- ad7 -->
                <img
                  src="../assets/ad7b21b5cb48b825.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Upper Body Workout</h2>
                  <p>
                    Upper limb exercise is crucial for enhancing muscle strength
                    and endurance in the arms, shoulders, chest, and back. For
                    beginners
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 下肢无氧 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="anaerobic">
              <section>
                <!-- 8c4 -->
                <img
                  src="../assets/8c41149e909ca979.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>How to lower body Exercises</h2>
                  <p>
                    Warm up exercises are also an important part of
                    exercise，Always warm up for 5-10 minutes before starting
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 下肢无氧 -->
          <!-- 8cb -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="anaerobic">
              <section>
                <img
                  src="../assets/8cb7592917ca67b2.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>How to Core Strength Exercises</h2>
                  <p>
                    1. Plank：Lie face down with your elbows under your
                    shoulders and feet hip-width apart.
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
        </el-tab-pane>

        <!-- 健康饮食 -->
        <el-tab-pane label="Diet" name="fourth">
          <!-- 健康饮食概述 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="diet">
              <section>
                <img
                  src="../assets/ecbcc6680451d943.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>A healthy diet</h2>
                  <p>
                    A healthy diet helps control calorie intake, which supports
                    maintaining a healthy weight and prevents obesity.
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 早餐 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="diet">
              <section>
                <img
                  src="../assets/39233ce1ccc8d5b1.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Breakfast</h2>
                  <p>
                    A high-quality breakfast can provide you with enough
                    protein, fat, and carbohydrates while working out, giving
                    you ample energy and supporting your muscles to self repair.
                    Here are our recommended breakfast recipes:
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 午餐 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="diet">
              <section>
                <img
                  src="../assets/8b409e13d99b5b96.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Lunch</h2>
                  <p>
                    After exercising, lunch should be rich in protein, healthy
                    carbohydrates, and some vegetables to help restore and
                    replenish energy. Here is a simple and nutritious fitness
                    lunch recommendation
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 晚餐 -->
          <el-card style="width: 100%" shadow="hover">
            <router-link to="diet">
              <section>
                <img
                  src="../assets/9d3a85a61e52d741.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Supper</h2>
                  <p>
                    After exercising, dinner also requires balanced protein,
                    healthy carbohydrates, and plenty of vegetables. Here is a
                    dinner recipe suitable for fitness after exercise
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
        </el-tab-pane>
        <!-- 恢复与放松 -->
        <el-tab-pane label="Recover" name="four">
          <!-- 放松概述 -->
          <el-card style="width: 100%" shadow="hover">
            <!-- de7 -->
            <router-link to="recover">
              <section>
                <img
                  src="../assets/de77aaaf4e85957a.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Relaxation overview</h2>
                  <p>
                    1. Stretching during relaxation increases the flexibility of
                    muscles and joints, thereby minimizing the risk of injuries
                    during future workouts.
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
          <!-- 放松方法 -->
          <el-card style="width: 100%" shadow="hover">
            <!-- a3f -->
            <router-link to="recover">
              <section>
                <img
                  src="../assets/a3fc60e5e1fe0011.jpg"
                  alt=""
                  style="width: 6rem; object-fit: cover"
                />
                <article>
                  <h2>Method of relaxing</h2>
                  <p>
                    Relaxation exercise is also an important part of fitness.
                    Here are some effective relaxation methods
                  </p>
                </article>
              </section>
            </router-link>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>

  <Footpolicy></Footpolicy>

  <!-- 卡片部分结束 -->

  <!-- 一件返回顶部 -->
  <el-backtop />
  <!-- 尾部 -->
  <Foot />
</template>

<script setup>
import { ref } from "vue";
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import Footpolicy from "../components/Footpolicy.vue";
const activeName = ref("first");
const handleClick = () => {};
</script>
<style scoped lang="scss">

@media screen and (max-width: 768px) {
  .box {
    width: 96% !important;
  }

  .merry {
    width: 96% !important;
    margin-top: 6.25rem !important;
  }

  /* // .el-card {
  //   width: 22.375rem !important;
  // } */
  .el-backtop {
    bottom: 60px !important;
    right: 20px !important;
  }
}


@media screen and (min-width: 769px) and (max-width: 992px) {
  .box {
    width: 95% !important;
  }

  .merry {
    width: 88% !important;
    margin-top: 6.25rem !important;
    flex-direction: column;
  }
}


@media screen and (min-width: 993px) and (max-width: 1200px) {
  .box {
    width: 100% !important;
  }

  .merry {
    width: 88% !important;
  }

  .el-breadcrumb {
    width: 88% !important;
  }

  .el-backtop[data-v-83883e7f] {
    right: 100px !important;
    bottom: 100px !important;
  }

  .leftswipper {
    margin-top: 48px;
  }
}


@media screen and (min-width: 1200px) {
  .el-backtop[data-v-83883e7f] {
    right: 360px !important;
    bottom: 100px !important;
  }

  .leftswipper {
    margin-top: 48px;
  }
}

.merry {
  width: 70%;
  margin: 0 auto;
  margin-top: 6.25rem;

  .el-card {
    margin-bottom: 1.4rem;
    border-radius: 0.875rem;
  }

  section {
    display: flex;
    box-sizing: border-box;

    article {
      margin-left: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #475669;

      h2 {
        font-size: 16px;
      }

      p {
        width: 12rem;
        white-space: nowrap;
        /* 文本不换行 */
        overflow: hidden;
        /* 隐藏超出部分 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
      }
    }
  }
}


.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

footer {
  a {
    color: #fff;
  }
}
</style>
