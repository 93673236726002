<template>
  <div>
    <p>
      A high-quality breakfast can provide you with enough protein, fat, and
      carbohydrates while working out, giving you ample energy and supporting
      your muscles to self repair. Here are our recommended breakfast recipes:
    </p>
    <h2>Ingredients:</h2>
    <ul>
      <li>1 cup rolled oats（200g）</li>
      <li>1 cup skim milk（250ml）</li>
      <li>2 egg（You can use 30g protein powder instead）</li>
      <li>1/2 cup blueberries (or other fruit of your choice)</li>
      <li>A serving of vegetables（recommended spinach）</li>
      <li>Moderate amount nuts（Add according to your preferences）</li>
    </ul>
    <h2>Instructions:</h2>
    <ul>
      <li>
        1. combine the rolled oats and milk In a small pot, Bring to a boil over
        then reduce to a simmer and cook for 5 minutes until the oats are soft.
      </li>
      <li>
        2. You can either fry egg or poach egg, depending on your preference.
      </li>
      <li>
        3. Place the cooked oats in a bowl，Top with the cooked egg,
        blueberries, spinach, and nuts. You can lightly steam the spinach if you
        prefer.
      </li>
    </ul>

    <h2>Tips：</h2>
    <ul>
      <li>
        1. Egg and milk provide high-quality protein to support muscle repair
        and growth.
      </li>
      <li>
        2. Oats, blueberries, and vegetables are rich in fiber and antioxidants,
        which are beneficial for digestion and overall health.
      </li>
      <li>3. Nuts provide healthy fats for sustained energy</li>
      <li>
        If you don't have time to prepare breakfast, we also have simple recipe
        recommendations
      </li>
    </ul>

    <h2>Ingredients:</h2>
    <ul>
      <li>1. 1 cup instant oats（200g）</li>
      <li>2. 50g cup nuts （almonds or peanuts）</li>
      <li>3. Moderate amount of honey and peanut butter</li>
    </ul>
    <h2>Instructions:</h2>
    <ul>
      <li>1. Mix oats, nuts, honey, and peanut butter thoroughly in a bowl.</li>
      <li>
        2. Press the mixture into a baking tray, refrigerate for 2-4 hour, and
        then cut into bars.
      </li>
      <li>3. You can eat it alone or pair it with skim milk</li>
    </ul>
    <h2>Ingredients:</h2>
    <ul>
      <li>1. 200g Fresh Fruit（Apple、banana、blueberry and others）</li>
      <li>2. 1 cup skim milk（250ml）</li>
      <li>3. A small amount of nuts and honey</li>
    </ul>
    <h2>Instructions:</h2>

    <ul>
      <li>
        1. Mix all ingredients evenly with a blender. Pour into a mug and enjoy
      </li>
      <li>2. Of course, you can also enjoy it with whole wheat bread.</li>
    </ul>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h2 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
  font-weight: 700;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
