<template>
  <div>
    <h1>Aerobic Exercise</h1>
    <p>
      Aerobic exercise is a type of physical activity primarily aimed at
      enhancing cardiovascular function. It typically involves repetitive
      movements of large muscle groups, effectively improving the body's
      endurance and metabolic rate.
    </p>
    <ul>
      <li>
        1. Types of Aerobic Exercise：Running、Swimming、Cycling、Jump
        Rope，Walking, done in the right way, is a form of aerobic exercise
      </li>
      <li>
        2. Aerobic exercise strengthens the heart and lungs, increasing oxygen
        efficiency. Regular aerobic activity helps burn calories, controlling
        body weight and fat levels. Exercise releases endorphins, improving mood
        and reducing symptoms of anxiety and depression. Helps lower the risk of
        cardiovascular diseases, diabetes, and some cancers. Moderate aerobic
        exercise can enhance immune function, increasing resistance to
        illnesses. Scientific aerobic exercise can bring better effect to the
        people with chronic disease than medicine.
      </li>
      <li>
        3. What should be considered when performing aerobic exercise? Warm up
        before starting and stretch afterward to reduce the risk of injury.
        Drink enough water during exercise to maintain hydration. Try different
        types of aerobic exercises to prevent fatigue and boredom. Choose the
        right intensity avoid overexertion leading to injury. If you feel
        discomfort or pain, adjust the intensity or stop the exercise.
      </li>
    </ul>
    <h3>
      By incorporating aerobic exercise into your routine, you can significantly
      improve your overall health.
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 10px 0;
}
p {
  margin-left: 14px;
  margin-bottom: 16px;
}
li {
  margin: 20px;
}
h3 {
  margin-left: 20px;
}
</style>
