<template>
  <div>
    <p>
      After exercising, lunch should be rich in protein, healthy carbohydrates,
      and some vegetables to help restore and replenish energy. Here is a simple
      and nutritious fitness lunch recommendation:
    </p>
    <h2>Ingredients:</h2>
    <ul>
      <li>1. 1 cup cooked quinoa（200g）</li>
      <li>2. 200g vegetable（spinach or cabbage etc.），shred</li>
      <li>3. 150g chicken breast</li>
      <li>4. One tomato、one cucumber and 1/2 avocado，sliced</li>
      <li>5. lemon juice and Olive oil（to flavor）</li>
    </ul>

    <h2>Instructions:</h2>
    <ul>
      <li>
        1. Use 2 parts water to 1 part quinoa, bringing it to a boil and then
        simmering for about 15 minutes add to bowl.
      </li>
      <li>
        2. Season the chicken breast with salt and pepper. In a skillet, heat a
        little olive oil and cook the chicken for about 4-6 minutes on each
        side, Remove and slice, add to bowl
      </li>
      <li>
        3. Put vegetables, cucumbers, tomatoes, and avocados into a bowl and
        stir well with chicken breast and quinoa.
      </li>
      <li>
        4. Drizzle with olive oil and lemon juice, season with your favorite
        seasonings (such as salt, black pepper), stir well, and you're ready to
        eat
      </li>
    </ul>
    <h2>Tips：</h2>
    <ul>
      <li>
        1. If you are a vegetarian，you can use tofu instead of chicken breast
        meat,The tofu or chicken breast provides high-quality protein to aid
        muscle recovery.
      </li>
      <li>
        2. Suggest washing vegetables clean and cooking them as much as possible
        before consumption.
      </li>
      <li>
        3. Quinoa and avocado are excellent sources of energy, and quinoa is
        rich in fiber and vitamins that the human body needs.
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
