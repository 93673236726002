<template>
  <div>
    <h1>Upper Body Workout</h1>
    <p>
      Upper limb exercise is crucial for enhancing muscle strength and endurance
      in the arms, shoulders, chest, and back. For beginners, it is recommended
      to start with basic movements and gradually increase the intensity to
      ensure safety and effectiveness.
    </p>
    <p>
      Warm up exercises are also an important part of exercise，Always warm up
      for 5-10 minutes before starting your workout with light exercises like
      jogging or dynamic stretches.
    </p>
    <ul>
      <li>
        1. Deltoids（Shoulders）：Dumbbell Lateral Raise，Stand or sit, holding
        a dumbbell in each hand. Raise your arms out to the sides until they are
        parallel to the ground, then slowly lower them.
      </li>
      <li>
        2. Chest and , triceps：Push-ups，Place your hands on the ground,
        keeping your body in a straight line. Lower yourself by bending your
        arms, then push back up.
      </li>
      <li>
        3. Triceps，Tricep Dips：Sit on a chair or bench, place your hands on
        the edge, and lower your body by bending your arms. Push back up to the
        starting position. If you want to increase the difficulty，you can keep
        your feet elevate them on another chair
      </li>
      <li>
        4. Biceps，Dumbbell Bicep Curl：Hold a dumbbell in each hand with your
        palms facing up. Curl the weights toward your shoulders, then lower them
        slowly.
      </li>
      <li>
        5. Deltoids、triceps and chest，Barbell Bench Press：Lie flat on a
        bench, hold a barbell above your chest, lower it slowly, then press it
        back up.
      </li>
      <h3>
        <b>Tips：</b>Take 30-60 seconds of rest between sets to allow your
        muscles to recover. Start with lighter weights to ensure proper form and
        gradually increase as you gain strength. In my experience that helps
        prevent injury
      </h3>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
