<template>
  <div>
    <h1>The Importance of Post-Exercise Relaxation</h1>
    <ul>
      <li>
        1. Stretching during relaxation increases the flexibility of muscles and
        joints, thereby minimizing the risk of injuries during future workouts.
        Effective relaxation techniques improve blood flow, facilitating quicker
        delivery of nutrients and oxygen to damaged muscles, thus speeding up
        recovery.
      </li>
      <li>
        2. After exercise, muscles experience micro-tears and fatigue. Proper
        relaxation helps release muscle tension and alleviate discomfort. During
        exercise, lactic acid accumulates in the muscles, contributing to
        fatigue. Relaxation techniques help expedite the removal of lactic acid,
        reducing post-exercise soreness.
      </li>
      <li>
        3. Relaxation after exercise alleviates physical tension and
        psychological stress, contributing to better sleep quality and overall
        rest. Adequate rest can help the body recover effectively, maintain your
        interest and motivation in exercise, and enable you to be more focused
        and efficient in future sports
      </li>
    </ul>
    <p>
      Post-exercise relaxation is essential for promoting recovery, enhancing
      performance, and supporting mental health. Incorporating relaxation
      techniques into your fitness routine can help you achieve your health and
      fitness goals more effectively.
    </p>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h2 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
  font-weight: 700;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
