<template>
  <div>
    <p>
      After exercising, dinner also requires balanced protein, healthy
      carbohydrates, and plenty of vegetables. Here is a dinner recipe suitable
      for fitness after exercise:
    </p>
    <h2>Ingredients:</h2>
    <ul>
      <li>1. 200g salmon（or Cod fish/Basa fish）</li>
      <li>2. 200g broccoli，shred</li>
      <li>3. 1 medium sweet potato</li>
      <li>4. 1 tablespoon olive oil and 1 clove garlic</li>
      <li>5. Salt and black pepper（or other seasonings）</li>
    </ul>
    <h2>Instructions:</h2>
    <ul>
      <li>
        1. Put garlic in a skillet and stir fry in olive oil until cooked，add
        the salmon and cook for about 3-5 minutes on each side, until golden and
        fully cooked.
      </li>
      <li>
        2. Steam the sweet potato for about 15-20 minutes until tender. If you
        use an oven, in the oven at 200°C (400°F) for 25-30 minutes until cooked
        through.
      </li>
      <li>
        3. Steam the broccoli for about 5-7 minutes until vibrant and still
        crisp.
      </li>
      <li>
        4. Put the cooked salmon、sweet potato, and broccoli into a bowl, add
        salt and black pepper or other seasonings you like, and ready to eat.
      </li>
    </ul>
    <h2>Tips：</h2>
    <ul>
      <li>
        1. If you don't like sweet potatoes, you can use corn or potatoes
        instead, sweet potatoes provide complex carbohydrates and are high in
        fiber, helping replenish energy.
      </li>
      <li>
        2. Salmon is rich in high-quality protein and Omega-3 fatty acids, which
        aid muscle recovery.
      </li>
      <li>
        3. Broccoli is packed with vitamins and antioxidants, supporting immune
        health.If you don't like broccoli, spinach is also a good choice
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h2 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
  font-weight: 700;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
