<template>
  <div>
    <h1>Swimming</h1>
    <p>
      Swimming is a full-body aerobic exercise typically performed in water. It
      can be both a recreational activity and a workout, suitable for
      individuals of all ages. There are various swimming styles, including
      freestyle, breaststroke, backstroke, and butterfly, all of which
      effectively engage the entire body and improve cardiovascular fitness.
    </p>
    <h3>The benefits of swimming</h3>
    <ul>
      <li>
        1. Swimming increases heart rate and lung capacity, contributing to
        better cardiovascular health. It engages multiple muscle groups, helping
        to build strength and endurance.
      </li>
      <li>
        2. As an effective aerobic exercise, swimming helps burn calories and
        aids in weight management. The buoyancy of water reduces stress on the
        joints, making it ideal for those with injuries or excess weight.
      </li>
      <li>
        3. Swimming can alleviate stress and improve mental health, providing a
        soothing effect.
      </li>
    </ul>
    <h3>How to Start Swimming</h3>
    <ul>
      <li>
        1. Select a comfortable, well-fitting swimsuit and goggles to minimize
        distractions while swimming. A kickboard can also help you adjust to the
        water.
      </li>
      <li>
        2. Start in shallow water, practicing standing, walking, and floating to
        acclimate to the sensation of being in water. Learn how to breathe while
        swimming. Practice blowing bubbles and inhaling when your face is above
        water. Begin learning the fundamentals of basic strokes, such as
        freestyle and breaststroke, focusing on arm and leg movements.
      </li>
      <li>
        3. If possible, enroll in swimming classes or hire a coach to help you
        master the correct techniques.
      </li>
      <li>
        4. Do some light stretching or warm-up exercises to help your body
        adjust to the water temperature. Aim to swim 2-3 times a week to
        gradually build your skills and fitness level. Don’t overexert yourself;
        take breaks when you feel tired.
      </li>
    </ul>
    <h3>
      Swimming is a comprehensive and efficient form of exercise that promotes
      physical health and mental well-being. By following these steps, you can
      gradually get started with swimming and enjoy the benefits of this
      wonderful activity.
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 20px 0;
}
p {
  padding: 0 20px;
}
h3 {
  margin: 6px;
}
ul li {
  margin: 22px;
}
</style>
