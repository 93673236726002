<template>
  <footer
    class="hidden-md-and-up"
    style="
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100vw;
      background-color: rgba(38, 30, 21);
      position: fixed;
      bottom: 0;
      height: 7%;
      font-size: 14px;
      color: #fff;
    "
  >
    <span v-for="item in menuItems" :key="item.index">
      <router-link
        :to="item.index"
        :class="{ 'active-menu-item': iscalss(item.index) }"
        @click="onspan(item.index)"
        >{{ item.text }}</router-link
      >
    </span>
    <!-- <span>
      <router-link to="/privacy">Privacy Policy</router-link>
    </span>
    <span>
      <router-link to="/userTerms">User Terms</router-link>
    </span> -->
  </footer>
</template>

<script setup>
import { ref } from "vue";
const activeIndex = ref(""); // 当前激活的菜单项索引
const menuItems = ref([
  { index: "/article/contact", text: "Contact US" },
  { index: "/article/privacy", text: "Privacy Policy" },
  { index: "/article/userTerms", text: "User Terms" },
]);
const onspan = (index) => {
  activeIndex.value = index;
};
const iscalss = (index) => {
  return activeIndex.value == index;
};
</script>

<style lang="scss" scoped>
.active-menu-item {
  color: #eebe77;
}
</style>
