<template>
  <div>
    <h1>Methods for Post-Exercise Relaxation</h1>
    <p>
      Relaxation exercise is also an important part of fitness. Here are some
      effective relaxation methods
    </p>
    <ul>
      <li>
        1. Use a foam roller or massage gun to massage tight muscles, relieving
        tension and soreness while improving blood circulation.
      </li>
      <li>
        2. Engage in 10-15 minutes of low-intensity exercise, such as walking or
        easy cycling, to help gradually bring your heart rate back to normal.
      </li>
      <li>
        3. Full Body Stretching，Focus on major muscle groups (such as legs,
        back, and shoulders) with static stretches. Hold each stretch for 10-15
        seconds, feeling a mild stretch without overexerting.
      </li>
      <li>
        4. Ensure you hydrate adequately after exercise to prevent dehydration.
        consume foods rich in protein and carbohydrates to aid muscle recovery.
        Take a warm bath or shower to help relax muscles and alleviate
        post-exercise discomfort.
      </li>
      <li>
        5. To ease the pain and reduce inflammation, ice your joint immediately
        after exercise. Soaking in a warm bath relaxes muscles after exercise.
      </li>
      <li>
        6. Ensure sufficient sleep time to give your body ample time to relieve
        fatigue and recover muscle damage
      </li>
    </ul>
    <h3>
      Incorporating these relaxation methods can effectively help your body
      recover, reduce fatigue, and promote mental relaxation. By adding these
      techniques to your fitness routine, you can enhance your overall workout
      effectiveness and maintain good health.
    </h3>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h2 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
  font-weight: 700;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
