<template>
  <div>
    <h1>Core Strength</h1>
    <h3>How to Core Strength Exercises</h3>
    <ul>
      <li>
        1. Plank：Lie face down with your elbows under your shoulders and feet
        hip-width apart. Keep your body in a straight line from head to heels.
        Hold for 45-90 seconds
      </li>
      <li>
        2. Crunch: Lie on your back with your knees bent and feet flat on the
        ground. Place your hands on your chest or behind your head. Use your
        abdominal muscles to lift your upper body, then lower it back down.
        Repeat 12-15 times.
      </li>
      <li>
        3. Bridge：Lie on your back with your knees bent and feet flat on the
        ground. Raise your hips until your body forms a straight line from your
        shoulders to your knees. Hold for a few seconds, then lower back down.
        Repeat 12-16 times.
      </li>
      <li>
        4. Russian Twist：Sit on the ground with your knees bent and feet lifted
        off the floor. Lean back slightly and hold your hands together. Twist
        your torso to the left, then to the right, aiming to touch the ground
        beside you. Repeat 10-15 times on each side.
      </li>
    </ul>

    <h3>Regular core strength training can help you enjoy these benefits：</h3>
    <ul>
      <li>
        1. Strong core muscles support the spine, potentially reducing the
        occurrence of lower back pain. many everyday movements rely on core
        strength, strong core muscles making these tasks easier and safer.
      </li>
      <li>
        2. Strong core muscles help maintain good posture, reducing stress on
        the back and neck. Whether jogging, swimming, or engaging in other
        sports, a strong core can improve power and efficiency. help you
        maintain balance during physical activities and lowering the risk of
        injury.
      </li>
      <li>
        3. Core training can improve circulation and metabolism, positively
        affecting fat loss and body composition.
      </li>
    </ul>
    <h3>
      Regular core strength training can help you enjoy these benefits,
      improving your quality of life and athletic performance.
    </h3>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h1 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
