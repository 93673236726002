<template>
  <div>
    <h1>Anaerobic Exercise</h1>
    <p>
      Anaerobic exercise is a type of physical activity primarily focused on
      increasing muscle strength and power, typically involving short bursts of
      high-intensity effort. Unlike aerobic exercise, anaerobic exercise relies
      mainly on energy stored in the muscles rather than on oxygen. Anaerobic
      exercise is effective for increasing strength, improving metabolism, and
      enhancing overall fitness.
    </p>
    <ul>
      <li>
        1. Types of Anaerobic Exercise：High-Intensity Interval Training
        (HIIT)、Bodyweight Training、Strength Training and Plyometric Training.
      </li>
      <li>
        2. The benefits of anaerobic exercise：Weight-bearing exercises
        strengthen bones, reducing the risk of osteoporosis. Although primarily
        focused on strength, anaerobic exercise can also benefit heart health.
        Enhances muscle size and strength, benefiting everyday activities and
        athletic performance. Increased muscle mass raises the basal metabolic
        rate, helping with weight management. Helps define muscle tone and
        improve overall body shape.
      </li>
      <li>
        3. In anaerobic exercise, increase of lactic acid and hydrogen ion lead
        to exercise-induced fatigue and the lower ability of physical activity
        in a short time. So warm up before preparing your muscles and joints,
        and ensure proper posture and technique during the workout to reduce the
        risk of injury. Allow enough recovery time for muscles between strength
        training sessions to prevent overtraining. Avoid jumping to heavier
        weights or higher intensity; gradually progress in your training. In
        addition adequate protein intake and guarantee the sufficient rest and
        sleep of yourself is essential for muscle repair and growth.
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  margin: 20px 0;
}
p {
  font-size: medium;
  padding: 0 20px;
  font-weight: 600;
}
ul li {
  margin: 22px;
}
</style>
