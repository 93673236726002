<template>
  <div>
    <h1>Privacy Policy</h1>
    <p>
      We are committed to protecting your privacy and ensuring the security of
      your personal information. This Privacy Policy explains how we collect,
      use, and safeguard your data when you visit our website.
    </p>
    <h3>1. Information Collection</h3>
    <ul>
      <li>
        We may collect personal information from you when you voluntarily
        provide it to us, such as when you register an account, subscribe to our
        newsletter, or fill out a contact form. The information we collect may
        include your name, email address, phone number, and any other
        information you choose to provide.
      </li>
      <li>
        We also automatically collect certain non-personal information when you
        visit our website, such as your IP address, browser type, operating
        system, and the pages you visit. This information is collected through
        cookies and similar technologies and helps us improve our website and
        enhance your user experience.
      </li>
    </ul>

    <h3>2. Information Usage</h3>
    <h4>We use the collected information for various purposes, including:</h4>
    <ul>
      <li>
        To personalize your experience on our website and provide you with
        relevant content.
      </li>
      <li>To improve our website and enhance its functionality.</li>
      <li>
        To communicate with you, respond to your inquiries, and provide customer
        support.
      </li>
      <li>
        To send you promotional materials, newsletters, and updates about our
        products and services.
      </li>
      <li>
        To analyze website usage and trends, and to gather demographic
        information.
      </li>
    </ul>
    <h3>3. Information Sharing</h3>
    <ul>
      <li>
        We do not sell, trade, or rent your personal information to third
        parties without your consent. However, we may share your information
        with trusted service providers who assist us in operating our website
        and conducting our business. These service providers are obligated to
        keep your information confidential and use it only for the purposes
        specified by us.
      </li>
      <li>
        We may also disclose your information if required by law or to protect
        our rights, property, or safety, or the rights, property, or safety of
        others.
      </li>
    </ul>
    <h3>4. Data Security</h3>
    <ul>
      <li>
        We take the security of your personal information seriously and
        implement various measures to protect it from unauthorized access,
        disclosure, alteration, or destruction. These measures include
        encryption, firewalls, and regular security audits. However, please note
        that no method of transmission over the internet or electronic storage
        is 100% secure, and we cannot guarantee absolute security.
      </li>
    </ul>
    <h3>5. User Rights</h3>
    <ul>
      <li>
        You have the right to access, update, and delete your personal
        information. If you would like to exercise any of these rights or have
        any questions or concerns about our Privacy Policy, please contact us
        using the information provided below.
      </li>
      <li>
        Thank you for choosing us. We value your privacy and appreciate your
        trust in us.
      </li>
    </ul>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
