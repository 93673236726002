<template>
    <div>
        <h1>Beginner Fitness Guide</h1>
        <section style="padding: 18px;">
            <h4>
                How should a beginner get started with fitness? Here are some beginner fitness tips to help you start
                from
                scratch and work out effectively and healthily.
            </h4>
            <p>
                1. First define your goals: Are you aiming to lose fat, build muscle, improve endurance, or just
                stay
                healthy?
                Different goals require different training plans. You can set some small goals that can be gradually
                achieved,
                such as increasing training intensity every week or reducing a certain amount of weight every month.
            </p>
            <p>
                2. Beginners can start with full-body strength workouts: Combining cardio and strength training is
                essential
                for
                overall fitness. Ensure you get enough sleep, as it helps your body recover. During the beginner
                phase,
                take
                at
                least 2-3 rest days per week to allow your muscles to recover and grow.
            </p>
            <p>
                3. Avoid common mistakes，Don't overtrain, as overtraining can lead to injuries. Don't skipping
                warm-ups
                and
                stretches: warming up before and stretching after your workout helps prevent injuries and improves
                flexibility.
                Maintain a regular sleep and workout schedule.
            </p>
            <p>
                4. Diet is also an important aspect. Consuming protein (chicken, fish, beef) supports muscle repair
                and
                growth.
                High quality carbohydrates and fats can provide energy for training，You can obtain these through
                oats,
                brown
                rice, avocados, and olive oil. At the same time drink plenty of water to stay hydrated.
            </p>
            <h3> Use a journal or app to log your workouts and progress. This helps you adjust your plan and stay
                motivated
                as
                you see improvements. If you're unsure where to start, consider hiring a professional trainer or working
                out
                with a friend. This can help improve your technique and make the experience more enjoyable.</h3>
        </section>

    </div>
</template>

<style lang="scss" scoped>
p {
    margin: 18px 0;
}
</style>