<template>
  <div>
    <h1>A healthy diet</h1>
    <ul>
      <li>
        1. A healthy diet helps control calorie intake, which supports
        maintaining a healthy weight and prevents obesity. Eating more whole
        grains, vegetables, fruits, and lean proteins aids in achieving a
        balanced weight.
      </li>
      <li>
        2. Healthy eating reduces the risk of chronic conditions such as heart
        disease, diabetes, high blood pressure, and certain cancers. Foods rich
        in dietary fiber (like whole grains, vegetables, and fruits) help lower
        cholesterol and blood sugar levels.
      </li>
      <li>
        3. A healthy diet, particularly one with reduced caffeine and alcohol
        intake, can help improve sleep quality, making it easier for the body to
        achieve deep, restful sleep.
      </li>
    </ul>
    <h3>How to properly combine foods</h3>
    <ul>
      <li>
        Opt for whole grains like brown rice, whole wheat, and other fiber-rich
        grains, while reducing the intake of refined and processed foods. Eat
        meals at regular intervals to avoid extreme hunger or binge eating.
        Control portion sizes and avoid consuming large amounts of food in one
        sitting. Eat more vegetables and fruits，Vegetables and fruits are rich
        in vitamins, minerals, and dietary fiber, helping to prevent chronic
        diseases.
      </li>
      <li>
        Moderate protein intake，Opt for healthy protein sources like fish,
        beef, and chicken，Protein is essential for maintaining bodily functions
        and tissue repair. You can use beans instead of meat to obtain protein
        if you are a vegetarian，Control portion sizes and avoid consuming too
        much or too little of any one food group. Reduce salt and sugar intake,
        drink plenty of water (about 1 liter) per day, avoid sugary drinks,
        alcohol, and excessive caffeine intake.
      </li>
      <li>
        A balanced diet is also an important step toward maintaining good
        health, and I hope these suggestions can help you.
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h2 {
  margin: 26px 0;
}
p {
  padding: 0 10px;
  margin: 18px 0;
  font-weight: 700;
}
ul li {
  margin: 22px;
}
h3 {
  margin-left: 20px;
}
</style>
